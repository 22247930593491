import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

// Components
import { ProfileCards } from 'components/ProfileCards';

// Local styles
import {
  SubscribeFormSection,
  SubscribeFormTitle,
} from 'components/Subscribe/local-styles';

// Styles
import { Center, MarginTop_xxl } from 'styles';

// Templates
import { FullWidth } from 'templates';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Types
import { CampOrClinic } from 'types';
interface Props {
  data: {
    campOrClinic: {
      data: CampOrClinic;
    };
  };
}

const CampOrClinicPageTemplate = ({ data }: Props) => {
  const title = get(data, 'campOrClinic.data.title') || 'Camp or clinic';
  const formUrl = get(data, 'campOrClinic.data.forms[0].data.link', '') || '';
  // const foundSponsors = get(data, 'campOrClinic.data.sponsors', []) || [];
  // const sponsors = foundSponsors ? foundSponsors.map(({ data }: Props) => data) : [];
  const markdownContent =
    get(data, 'campOrClinic.data.markdown_content', '') || '';
  const clinicians = get(data, 'campOrClinic.data.clinicians', []) || [];

  return (
    <SEOContent title={title}>
      <PageLayout>
        <h1 css={[Center, MarginTop_xxl]}>{title}</h1>
        <FullWidth markdownContent={markdownContent} />
        {clinicians.length > 0 && (
          <ProfileCards maxWidth={30} content={clinicians} />
        )}
        {formUrl && (
          <SubscribeFormSection>
            <h3 css={SubscribeFormTitle}>
              <a href={formUrl} target="_blank" rel="noopener noreferrer">
                Sign up for this clinic
              </a>
            </h3>
          </SubscribeFormSection>
        )}
      </PageLayout>
    </SEOContent>
  );
};

export default CampOrClinicPageTemplate;

export const query = graphql`
  query ($campOrClinicSlug: String) {
    campOrClinic: airtable(
      table: { eq: "Camps And Clinics" }
      fields: { campOrClinicSlug: { eq: $campOrClinicSlug } }
    ) {
      data {
        title
        markdown_content
        form_url
        attachments {
          localFiles {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        sponsors {
          data {
            sponsor_name
            sponsor_url
            attachments {
              id
              localFiles {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        clinicians {
          data {
            id
            full_name
            specialties
            profile_picture {
              thumbnails {
                large {
                  url
                }
              }
            }
            bio
          }
        }
        forms {
          data {
            link
            id
          }
        }
      }
    }
  }
`;
